<template>
  <v-app-bar id="default-app-bar" app absolute class="v-bar--underline" color="transparent" :clipped-left="$vuetify.rtl" :clipped-right="!$vuetify.rtl" height="70" flat>
    <div v-if="$vuetify.breakpoint.mdAndUp"></div>
    <div v-else>
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />
      <default-drawer-toggle class="hidden-sm-and-down" />
    </div>
    <v-toolbar-title class="font-weight-light text-h5" />
    <v-spacer />
    
    <v-menu offset-y open-on-hover>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">
          <v-icon class="mr-2">mdi-translate</v-icon>
          {{ $t('changeLang') }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="updateLang(item)" v-for="(item, index) in langCollection" :key="index">
          <v-list-item-title>{{ item }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-toolbar-title class="font-weight-bold text-h5 ml-5 mr-3" v-text="tenantName" />
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="tenantCollectionDropdown.length > 1" style="margin:10px;" v-bind="attrs" v-on="on" fab elevation="0">
          <v-icon>mdi-airplane</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">SELECT THE TENANT</v-card-title>
        <v-card-text>
          <v-autocomplete v-model="temp.tenant" :items="tenantCollectionDropdown" label="Tenant" hide-details="auto" dense autocomplete="nope" outlined></v-autocomplete>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="travel()">TRAVEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-toolbar-title class="font-weight-bold text-h5 ml-5 mr-3" v-text="this.userProfile.username" />
    <notifications />
    <v-btn style="margin:10px;" fab elevation="0" @click="triggerPullDown()"><v-icon>mdi-dots-grid</v-icon></v-btn>
    <v-btn style="margin:10px;" fab elevation="0" @click="triggerCalendarDialog()"><v-icon>mdi-calendar</v-icon></v-btn>
    <!-- <default-account /> -->
  </v-app-bar>
</template>

<script>
// Utilities
import { get, sync, call } from "vuex-pathify";

export default {
  name: "DefaultBar",
  created() {
    this.lang = localStorage.getItem("lang");
    console.log(this.lang);
  },
  data: () => ({
    dialog: false,
    lang: '',
    langCollection: [
      "en-US",
      "pt-BR",
      "it-IT"
    ],
    temp: {
      tenant: localStorage.getItem('tenantUuid')
    }
  }),
  components: {
    DefaultAccount: () =>
      import(
        /* webpackChunkName: "default-account" */
        "./widgets/Account"
      ),
    Notifications: () =>
      import(
        "../../components/utility/MFNotifications"
      ),
    DefaultDrawerToggle: () =>
      import(
        /* webpackChunkName: "default-drawer-toggle" */
        "./widgets/DrawerToggle"
      ),
    // DefaultGoHome: () =>
    //   import(
    //     /* webpackChunkName: "default-go-home" */
    //     "./widgets/GoHome"
    //   ),

    // DefaultNotifications: () =>
    //   import(
    //     /* webpackChunkName: "default-notifications" */
    //     "./widgets/Notifications"
    //   ),
    
    // DefaultSearch: () =>
    //   import(
    //     /* webpackChunkName: "default-search" */
    //     "./widgets/Search"
    //   ),
  },
  computed: {
    ...sync("app", ["drawer", "mini"]),
    name: get("route/name"),
    tenantCollectionDropdown: get('tenantConfiguration/tenantCollectionDropdown'),
    tenantName: get('tenantConfiguration/tenantName'),
    ...sync("auth", ["userProfile"]),
  },
  methods: {
    travel() {
      this.setAll(this.temp.tenant);
      this.dialog = false
    },
    updateLang(lang = 'en-US') {
      localStorage.setItem("lang", lang);
      this.$forceUpdate();
      window.location.reload();
    },
    ...call("utils/*"),
    ...call('tenantConfiguration/*'),
  }
};
</script>
